.navbar {
    border: none;
    border-radius: 0;
    background-color: $primary-blue;

    &,
    .form-control {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            font-size: 14px;
        }
    }

    .dropdown-toggle {
        padding-left: 0;
        padding-right: 0;

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: transparent;
        }

    }
}

.navbar-collapse {
    padding: 0;
}

.navbar-nav > li > {

    a {
        @media (min-width: $screen-sm-min) {
            padding-left: spacing(tiny);
            padding-right: spacing(tiny);
        }
        @media (min-width: $screen-md-min) {
            padding-left: spacing(small);
            padding-right: spacing(small);
        }


        &:hover,
        &:focus {
            background-color: darken($primary-blue, 10%);
        }
    }

    .dropdown-menu {
        border-radius: 4px;
    }

}

.navbar-brand {
    padding-left: spacing(small);
    padding-right: spacing(small);
    padding-top: 13px;
    padding-bottom: 17px;
    color: $light-text;

    &,
    &:hover,
    &:focus {
        color: $light-text;
    }

}

.navbar-form {
    margin: 0;
    padding-left: 0;
    padding-right: spacing(tiny);
    @media (min-width: $screen-md-min) {
        padding-right: spacing(small);
    }

    .input-group {
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            width: 165px;
        }
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            width: 180px;
        }
    }

    .navbar-right {
        margin-bottom: 0px;
    }

}

.navbar-toggle {

    .icon-bar {
        background-color: #fff;
    }

}

.navbar-nav a {
    color: #fff;
}
