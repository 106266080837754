.main {
    padding-top: spacing(medium);
    padding-bottom: spacing(medium);

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        width: auto;
        padding-left: spacing(base);
        padding-right: spacing(base);
    }
}
