.u-border-top {
    border-top: 1px solid $component-border-color !important;
}

.u-border-bottom {
    border-bottom: 1px solid $component-border-color !important;
}

.u-border-right {
    border-right: 1px solid $component-border-color !important;
}

.u-border-left {
    border-left: 1px solid $component-border-color !important;
}

.u-undo-border-top {
    border-top: none!important;
}

.u-no-border {
    border: none!important;
}
