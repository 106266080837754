.nav-tabs + .tab-content {
    margin-top: -1px;
    padding: spacing(medium);
    background-color: #fff;
    border: 1px solid $component-border-color;
}

.nav-tabs, .nav-pills {

    > li {

        // Active state

        &.active > a {
            &,
            &:hover,
            &:focus {
                background-color: $component-emphatised-bg;
                color: $text-color;
            }
        }

    }

}

.nav-tabs > li, .nav-pills > li {

    > a {
        font-size: $font-size-small;
        text-transform: uppercase;
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: $component-emphatised-bg;
        }

    }

    + li {
        margin-left: spacing(tiny);
    }

}


