@mixin link-style {
    color: $link-color;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $link-hover-color;
    }

}
