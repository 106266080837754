.u-display-flex {
  display: flex !important;
}

.u-justify-content-space-between {
  justify-content: space-between !important;
}

.u-justify-content-flex-end {
  justify-content: flex-end !important;
}

.u-align-items-center {
  align-items: center !important;
}

.u-flex-direction-column {
  flex-direction: column !important;
}

.u-flex-direction-row-reverse {
  flex-direction: row-reverse;
}
