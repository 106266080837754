.heading-label {
    @include heading;
    font-size: $font-size-small;
    text-transform: uppercase;
}

.heading-section {
    @include heading;
    font-size: $font-size-h2;
}

.heading-subsection {
    @include heading;
    font-size: $font-size-h3;
}
