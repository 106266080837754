.panel {
    margin-bottom: initial;
    box-shadow: none;

    &,
    .panel-group & {

        border-radius: 0;
    }

}

.panel-group {
    margin-bottom: initial;
}

.panel-heading {
    padding: spacing(base);

    .panel-default > & {
        background-color: $component-emphatised-bg;
    }

}

.panel-title {
    @include heading;
    font-size: $font-size-h4;
}
