.logged-name {
   max-width: 100px;
   display: inline-block;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: left;
   word-wrap: break-all;
   word-break: break-all;
}
