.checkbox-slider--b-flat input:disabled + span,
.checkbox-slider--b input:disabled + span {
  color: #666;
}

.checkbox-slider--b input + span,
.checkbox-slider--b-flat input + span {
    padding-left: 27px;
}

checkbox-slider input[type="checkbox"]:focus + *:before, .checkbox-slider--default input[type="checkbox"]:focus + *:before, .checkbox-slider--a-rounded input[type="checkbox"]:focus + *:before, .checkbox-slider--a input[type="checkbox"]:focus + *:before, .checkbox-slider--b input[type="checkbox"]:focus + *:before, .checkbox-slider--b-flat input[type="checkbox"]:focus + *:before, .checkbox-slider--c input[type="checkbox"]:focus + *:before, .checkbox-slider--c-weight input[type="checkbox"]:focus + *:before,
    .checkbox-toggle input[type="checkbox"]:focus + *:before {
    outline: none;
}
