.u-font-size-large {
    font-size: $font-size-large !important;
}

.u-font-size-small {
    font-size: $font-size-small !important;
}

.u-font-bold {
    font-weight: bold !important;
}

.u-font-italic {
    font-style: italic !important;
}

.u-color-white {
    color: #fff !important;
}

.u-text-align-center {
    text-align: center;
}

.u-text-truncated {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
