.modal-dialog {
    @media (min-width: $screen-sm-min) {
        margin-top: spacing(large);
        margin-bottom: spacing(large);
    }
}

.modal-multi-dialog {
    padding-right: 6px;
    padding-left: 6px;
}

.modal-multi {
    margin-right: 16px;
}

.modal-header {
    padding: spacing(base);
}

.modal-title {
    @include heading;
    font-size: $font-size-h1;
}

.modal-body {
    padding: spacing(base);
}
