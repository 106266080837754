.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

.page-header__title {
    @include heading;
    font-size: $font-size-h1;
}

.page-header__subtitle {
    font-size: $font-size-h2;
    line-height: $headings-line-height;
    font-weight: normal;
    color: #818A91;
}

.page-header__link {
    @include link-style;
    font-size: $font-size-small;
    font-weight: normal;
}