.list-item {
    padding: spacing(tiny) spacing(small);
    border-top: 1px solid $component-border-color;
}

.list-item--header {
    padding: spacing(small);
    font-size: $font-size-small;
    line-height: $headings-line-height;
    font-weight: bold;
    text-transform: uppercase;
    border-top: none;
}
