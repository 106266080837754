body {
  font-size: 14px;
  line-height: 1.5;
  background-color: #f9f9f9;
}
@media (min-width: 992px) {
  body {
    font-size: 15px;
  }
}

a {
  color: #41C6EF;
  cursor: pointer;
}
a:hover, a:focus {
  color: #12a5d2;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: initial;
  font-weight: normal;
}

h1:not([class]), .h1,
h2:not([class]), .h2,
h3:not([class]), .h3,
h4:not([class]), .h4,
h5:not([class]), .h5,
h6:not([class]), .h6 {
  font-weight: 500;
  margin-bottom: 0.75em;
}

h1:not([class]), .h1 {
  font-size: 24px;
}

h2:not([class]), .h2 {
  font-size: 21px;
}

h3:not([class]), .h3 {
  font-size: 19px;
}

h4:not([class]), .h4 {
  font-size: 16px;
}

h5:not([class]), .h5 {
  font-size: 16px;
}

h6:not([class]), .h6 {
  font-size: 16px;
}

p:not([class]) + h1:not([class]), p:not([class]) + h2:not([class]), p:not([class]) + h3:not([class]), p:not([class]) + h4:not([class]), p:not([class]) + h5:not([class]), p:not([class]) + h6:not([class]), ul:not([class]) + h1:not([class]), ul:not([class]) + h2:not([class]), ul:not([class]) + h3:not([class]), ul:not([class]) + h4:not([class]), ul:not([class]) + h5:not([class]), ul:not([class]) + h6:not([class]), ol:not([class]) + h1:not([class]), ol:not([class]) + h2:not([class]), ol:not([class]) + h3:not([class]), ol:not([class]) + h4:not([class]), ol:not([class]) + h5:not([class]), ol:not([class]) + h6:not([class]), dl:not([class]) + h1:not([class]), dl:not([class]) + h2:not([class]), dl:not([class]) + h3:not([class]), dl:not([class]) + h4:not([class]), dl:not([class]) + h5:not([class]), dl:not([class]) + h6:not([class]) {
  padding-top: 22.5px;
}

.selected-treatment-div {
  border: 1px solid;
  border-color: #08c4f4;
  box-shadow: 2px 3px #08c4f4;
}

.main {
  padding-top: 45px;
  padding-bottom: 45px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .main {
    width: auto;
    padding-left: 22.5px;
    padding-right: 22.5px;
  }
}

.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

.page-header__title {
  font-weight: 500;
  line-height: 1.1;
  font-size: 24px;
}

.page-header__subtitle {
  font-size: 21px;
  line-height: 1.1;
  font-weight: normal;
  color: #818A91;
}

.page-header__link {
  color: #41C6EF;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
}
.page-header__link:hover, .page-header__link:focus {
  color: #12a5d2;
}

.btn {
  padding-bottom: 9px;
  font-size: inherit;
  line-height: 1.5;
  border: none;
}
.btn, .btn:active, .btn.active {
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
}
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none;
}
.btn .glyphicon {
  line-height: inherit;
}

.btn-lg {
  padding-bottom: 13px;
  font-size: 17px;
  line-height: 1.3333333;
}

.btn-sm {
  padding-bottom: 8px;
  font-size: 13px;
  line-height: 1.5;
}

.btn-xs {
  padding-bottom: 4px;
  font-size: 13px;
  line-height: 1.5;
}

.btn-default {
  color: #333333;
  background-color: #ECEEEF;
  border-color: #ECEEEF;
}
.btn-default:active, .btn-default.active, .btn-default:hover, .btn-default.hover, .btn-default:focus, .btn-default.focus, .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus {
  color: #333333;
  background-color: #d0d5d8;
  border-color: #d0d5d8;
}

.btn-primary {
  color: #fff;
  background-color: #41C6EF;
  border-color: #41C6EF;
}
.btn-primary:active, .btn-primary.active, .btn-primary:hover, .btn-primary.hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus {
  color: #fff;
  background-color: #14b7e9;
  border-color: #14b7e9;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success:active, .btn-success.active, .btn-success:hover, .btn-success.hover, .btn-success:focus, .btn-success.focus, .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
}

.btn-no-clickable {
  cursor: auto;
}
.btn-no-clickable:active {
  box-shadow: none;
}

input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
  outline: none;
}

/* Numeric inputs */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control {
  font-size: 14px;
  line-height: 1.5;
}
@media (min-width: 992px) {
  .form-control {
    font-size: 15px;
  }
}
.form-control[disabled] {
  color: rgba(51, 51, 51, 0.5);
}

.form-control--w100 {
  width: 100px;
}

.form-control--w150 {
  width: 150px;
}

label,
.control-label {
  display: inline-block;
  margin-bottom: 5.625px;
  font-weight: bold;
}

.unit-label {
  display: inline-block;
  margin-bottom: 5.625px;
}

.form-group {
  margin-bottom: 22.5px;
}

.form-inline .form-group {
  margin-right: 11.25px;
}
.form-inline .form-group:last-child {
  margin-left: 0;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 5.625px;
  }
}

.uib-time input {
  width: 60px;
}

.input-group-addon {
  padding-left: 5.625px;
  padding-right: 5.625px;
  font-size: 13px;
}

.min-width-70 {
  min-width: 70px;
}

.icon-group {
  white-space: nowrap;
}
.has-success .icon-group {
  color: #3c763d;
}
.has-error .icon-group {
  color: #a94442;
}
.icon-group .glyphicon {
  margin-left: 11.25px;
  cursor: pointer;
}
.icon-group .glyphicon:first-child {
  margin-left: 0;
}
.icon-group .glyphicon.disabled {
  cursor: not-allowed;
  color: #999;
}

.table {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: initial;
}
@media (min-width: 992px) {
  .table {
    font-size: 15px;
  }
}
.table > thead > tr.active,
.table > tbody > tr.active,
.table > tfoot > tr.active {
  cursor: not-allowed;
}
.table > thead > tr.disabled > td,
.table > tbody > tr.disabled > td,
.table > tfoot > tr.disabled > td {
  color: #999;
  pointer-events: none;
  background-color: #f9f9f9;
}
.table > thead > tr.disabled .btn-default,
.table > tbody > tr.disabled .btn-default,
.table > tfoot > tr.disabled .btn-default {
  color: #999;
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 7px 10px;
  vertical-align: middle;
}
@media (min-width: 992px) {
  .table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    padding: 7px 20px;
  }
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  font-size: 13px;
  text-transform: uppercase;
  border-bottom: none;
}
.table > thead > tr > td.table__add-row,
.table > tbody > tr > td.table__add-row,
.table > tfoot > tr > td.table__add-row {
  padding-top: 16px;
  vertical-align: top;
}
.table > thead > tr > td.table__add-row .glyphicon,
.table > tbody > tr > td.table__add-row .glyphicon,
.table > tfoot > tr > td.table__add-row .glyphicon {
  cursor: pointer;
}
.table th .form-control {
  font-weight: normal;
}
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding-top: 11.25px;
  padding-bottom: 11.25px;
}

.table-hover > tbody > tr:hover {
  background-color: #f4fcfe;
}

.table--box tbody {
  border: 1px solid #ddd;
  background-color: #fff;
}
.table--box td {
  max-width: 300px;
}

.table--text-center th,
.table--text-center td {
  text-align: center;
}

.table--week th {
  min-width: 125px;
}
.table--week th > span.icon-extrahospitalary {
  color: grey;
}
.table--week th > span.icon-hospitalary {
  color: turquoise;
}
.table--week td:hover, .table--week td:focus {
  background-color: #f4fcfe;
}

.navbar {
  border: none;
  border-radius: 0;
  background-color: #41C6EF;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar,
.navbar .form-control {
    font-size: 14px;
  }
}
.navbar .dropdown-toggle {
  padding-left: 0;
  padding-right: 0;
}
.navbar .dropdown-toggle:hover, .navbar .dropdown-toggle:focus {
  text-decoration: none;
  background-color: transparent;
}

.navbar-collapse {
  padding: 0;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-left: 5.625px;
    padding-right: 5.625px;
  }
}
@media (min-width: 992px) {
  .navbar-nav > li > a {
    padding-left: 11.25px;
    padding-right: 11.25px;
  }
}
.navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
  background-color: #14b7e9;
}
.navbar-nav > li > .dropdown-menu {
  border-radius: 4px;
}

.navbar-brand {
  padding-left: 11.25px;
  padding-right: 11.25px;
  padding-top: 13px;
  padding-bottom: 17px;
  color: #FFF;
}
.navbar-brand, .navbar-brand:hover, .navbar-brand:focus {
  color: #FFF;
}

.navbar-form {
  margin: 0;
  padding-left: 0;
  padding-right: 5.625px;
}
@media (min-width: 992px) {
  .navbar-form {
    padding-right: 11.25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-form .input-group {
    width: 165px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-form .input-group {
    width: 180px;
  }
}
.navbar-form .navbar-right {
  margin-bottom: 0px;
}

.navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-nav a {
  color: #fff;
}

.logged-name {
  max-width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  word-wrap: break-all;
  word-break: break-all;
}

.fc-agendaWeek-view tr {
  height: 40px;
}

.fc-agendaDay-view tr {
  height: 40px;
}

.uib-datepicker-popup:focus, .uib-datepicker-popup:active, .uib-datepicker-popup:hover,
.uib-datepicker-popup:focus,
.uib-datepicker-popup:active,
.uib-datepicker-popup:hover,
.uib-datepicker-popup:focus,
.uib-datepicker-popup:active,
.uib-datepicker-popup:hover {
  outline: 0;
}
.uib-datepicker-popup .btn,
.uib-datepicker-popup .btn,
.uib-datepicker-popup .btn {
  border-radius: 0;
}

.fc-view-container {
  background-color: #fff;
}

@media (min-width: 768px) {
  .modal-dialog {
    margin-top: 67.5px;
    margin-bottom: 67.5px;
  }
}

.modal-multi-dialog {
  padding-right: 6px;
  padding-left: 6px;
}

.modal-multi {
  margin-right: 16px;
}

.modal-header {
  padding: 22.5px;
}

.modal-title {
  font-weight: 500;
  line-height: 1.1;
  font-size: 24px;
}

.modal-body {
  padding: 22.5px;
}

.close {
  outline: none;
}

.btn-list .btn {
  margin-bottom: 5.625px;
  margin-right: 5.625px;
}
.btn-list .btn:first-child {
  margin-left: 0;
}

.box {
  padding: 22.5px;
  background-color: #fff;
  border: 1px solid #ddd;
}
@media (min-width: 992px) {
  .box {
    padding: 45px;
  }
}

.nav-tabs + .tab-content {
  margin-top: -1px;
  padding: 45px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus, .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  background-color: #ECEEEF;
  color: #333333;
}

.nav-tabs > li > a, .nav-pills > li > a {
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 0;
}
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus, .nav-pills > li > a:hover, .nav-pills > li > a:focus {
  background-color: #ECEEEF;
}
.nav-tabs > li + li, .nav-pills > li + li {
  margin-left: 5.625px;
}

.heading-label {
  font-weight: 500;
  line-height: 1.1;
  font-size: 13px;
  text-transform: uppercase;
}

.heading-section {
  font-weight: 500;
  line-height: 1.1;
  font-size: 21px;
}

.heading-subsection {
  font-weight: 500;
  line-height: 1.1;
  font-size: 19px;
}

.panel {
  margin-bottom: initial;
  box-shadow: none;
}
.panel, .panel-group .panel {
  border-radius: 0;
}

.panel-group {
  margin-bottom: initial;
}

.panel-heading {
  padding: 22.5px;
}
.panel-default > .panel-heading {
  background-color: #ECEEEF;
}

.panel-title {
  font-weight: 500;
  line-height: 1.1;
  font-size: 16px;
}

.data-clock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 205px;
}

.list-group .list-group-item {
  border-radius: 0;
}
.list-group .list-group-item span.icon-hospitalary {
  color: turquoise;
}

.btn-group .btn {
  border-color: rgba(0, 0, 0, 0.1);
}

.fc-event-dot {
  margin-top: 4px;
}

.list-item {
  padding: 5.625px 11.25px;
  border-top: 1px solid #ddd;
}

.list-item--header {
  padding: 11.25px;
  font-size: 13px;
  line-height: 1.1;
  font-weight: bold;
  text-transform: uppercase;
  border-top: none;
}

.checkbox-slider--b-flat input:disabled + span,
.checkbox-slider--b input:disabled + span {
  color: #666;
}

.checkbox-slider--b input + span,
.checkbox-slider--b-flat input + span {
  padding-left: 27px;
}

checkbox-slider input[type=checkbox]:focus + *:before, .checkbox-slider--default input[type=checkbox]:focus + *:before, .checkbox-slider--a-rounded input[type=checkbox]:focus + *:before, .checkbox-slider--a input[type=checkbox]:focus + *:before, .checkbox-slider--b input[type=checkbox]:focus + *:before, .checkbox-slider--b-flat input[type=checkbox]:focus + *:before, .checkbox-slider--c input[type=checkbox]:focus + *:before, .checkbox-slider--c-weight input[type=checkbox]:focus + *:before,
.checkbox-toggle input[type=checkbox]:focus + *:before {
  outline: none;
}

.collapse-arrow {
  padding: 4px 10px;
}

.separate-arrow {
  padding-top: 4px;
  margin: 0px 5px;
}

.dropdown-menu.discharge-alerts {
  display: block;
  background-color: initial;
  position: fixed;
  top: initial;
  left: initial;
  bottom: 0;
  right: 0;
  border: initial;
  margin: initial;
  padding: 10px;
}
.dropdown-menu.discharge-alerts li {
  margin-top: 10px;
}
.dropdown-menu.discharge-alerts li a {
  border-radius: 4px;
  background-color: #d9534f;
  padding: 10px 20px;
}
.dropdown-menu.discharge-alerts li a:hover, .dropdown-menu.discharge-alerts li a:focus {
  background-color: #a94442;
}

.u-margin-tiny {
  margin: 5.625px !important;
}

.u-undo-margin-tiny {
  margin: -5.625px !important;
}

.u-margin-top-tiny {
  margin-top: 5.625px !important;
}

.u-undo-margin-top-tiny {
  margin-top: -5.625px !important;
}

.u-margin-right-tiny {
  margin-right: 5.625px !important;
}

.u-undo-margin-right-tiny {
  margin-right: -5.625px !important;
}

.u-margin-bottom-tiny {
  margin-bottom: 5.625px !important;
}

.u-undo-margin-bottom-tiny {
  margin-bottom: -5.625px !important;
}

.u-margin-left-tiny {
  margin-left: 5.625px !important;
}

.u-undo-margin-left-tiny {
  margin-left: -5.625px !important;
}

.u-margin-small {
  margin: 11.25px !important;
}

.u-undo-margin-small {
  margin: -11.25px !important;
}

.u-margin-top-small {
  margin-top: 11.25px !important;
}

.u-undo-margin-top-small {
  margin-top: -11.25px !important;
}

.u-margin-right-small {
  margin-right: 11.25px !important;
}

.u-undo-margin-right-small {
  margin-right: -11.25px !important;
}

.u-margin-bottom-small {
  margin-bottom: 11.25px !important;
}

.u-undo-margin-bottom-small {
  margin-bottom: -11.25px !important;
}

.u-margin-left-small {
  margin-left: 11.25px !important;
}

.u-undo-margin-left-small {
  margin-left: -11.25px !important;
}

.u-margin {
  margin: 22.5px !important;
}

.u-undo-margin {
  margin: -22.5px !important;
}

.u-margin-top {
  margin-top: 22.5px !important;
}

.u-undo-margin-top {
  margin-top: -22.5px !important;
}

.u-margin-right {
  margin-right: 22.5px !important;
}

.u-undo-margin-right {
  margin-right: -22.5px !important;
}

.u-margin-bottom {
  margin-bottom: 22.5px !important;
}

.u-undo-margin-bottom {
  margin-bottom: -22.5px !important;
}

.u-margin-left {
  margin-left: 22.5px !important;
}

.u-undo-margin-left {
  margin-left: -22.5px !important;
}

.u-margin-medium {
  margin: 45px !important;
}

.u-undo-margin-medium {
  margin: -45px !important;
}

.u-margin-top-medium {
  margin-top: 45px !important;
}

.u-undo-margin-top-medium {
  margin-top: -45px !important;
}

.u-margin-right-medium {
  margin-right: 45px !important;
}

.u-undo-margin-right-medium {
  margin-right: -45px !important;
}

.u-margin-bottom-medium {
  margin-bottom: 45px !important;
}

.u-undo-margin-bottom-medium {
  margin-bottom: -45px !important;
}

.u-margin-left-medium {
  margin-left: 45px !important;
}

.u-undo-margin-left-medium {
  margin-left: -45px !important;
}

.u-margin-large {
  margin: 67.5px !important;
}

.u-undo-margin-large {
  margin: -67.5px !important;
}

.u-margin-top-large {
  margin-top: 67.5px !important;
}

.u-undo-margin-top-large {
  margin-top: -67.5px !important;
}

.u-margin-right-large {
  margin-right: 67.5px !important;
}

.u-undo-margin-right-large {
  margin-right: -67.5px !important;
}

.u-margin-bottom-large {
  margin-bottom: 67.5px !important;
}

.u-undo-margin-bottom-large {
  margin-bottom: -67.5px !important;
}

.u-margin-left-large {
  margin-left: 67.5px !important;
}

.u-undo-margin-left-large {
  margin-left: -67.5px !important;
}

.u-margin-huge {
  margin: 90px !important;
}

.u-undo-margin-huge {
  margin: -90px !important;
}

.u-margin-top-huge {
  margin-top: 90px !important;
}

.u-undo-margin-top-huge {
  margin-top: -90px !important;
}

.u-margin-right-huge {
  margin-right: 90px !important;
}

.u-undo-margin-right-huge {
  margin-right: -90px !important;
}

.u-margin-bottom-huge {
  margin-bottom: 90px !important;
}

.u-undo-margin-bottom-huge {
  margin-bottom: -90px !important;
}

.u-margin-left-huge {
  margin-left: 90px !important;
}

.u-undo-margin-left-huge {
  margin-left: -90px !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-right-none {
  margin-right: 0 !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-left-none {
  margin-left: 0 !important;
}

.u-padding-tiny {
  padding: 5.625px !important;
}

.u-undo-padding-tiny {
  padding: -5.625px !important;
}

.u-padding-top-tiny {
  padding-top: 5.625px !important;
}

.u-undo-padding-top-tiny {
  padding-top: -5.625px !important;
}

.u-padding-right-tiny {
  padding-right: 5.625px !important;
}

.u-undo-padding-right-tiny {
  padding-right: -5.625px !important;
}

.u-padding-bottom-tiny {
  padding-bottom: 5.625px !important;
}

.u-undo-padding-bottom-tiny {
  padding-bottom: -5.625px !important;
}

.u-padding-left-tiny {
  padding-left: 5.625px !important;
}

.u-undo-padding-left-tiny {
  padding-left: -5.625px !important;
}

.u-padding-small {
  padding: 11.25px !important;
}

.u-undo-padding-small {
  padding: -11.25px !important;
}

.u-padding-top-small {
  padding-top: 11.25px !important;
}

.u-undo-padding-top-small {
  padding-top: -11.25px !important;
}

.u-padding-right-small {
  padding-right: 11.25px !important;
}

.u-undo-padding-right-small {
  padding-right: -11.25px !important;
}

.u-padding-bottom-small {
  padding-bottom: 11.25px !important;
}

.u-undo-padding-bottom-small {
  padding-bottom: -11.25px !important;
}

.u-padding-left-small {
  padding-left: 11.25px !important;
}

.u-undo-padding-left-small {
  padding-left: -11.25px !important;
}

.u-padding {
  padding: 22.5px !important;
}

.u-undo-padding {
  padding: -22.5px !important;
}

.u-padding-top {
  padding-top: 22.5px !important;
}

.u-undo-padding-top {
  padding-top: -22.5px !important;
}

.u-padding-right {
  padding-right: 22.5px !important;
}

.u-undo-padding-right {
  padding-right: -22.5px !important;
}

.u-padding-bottom {
  padding-bottom: 22.5px !important;
}

.u-undo-padding-bottom {
  padding-bottom: -22.5px !important;
}

.u-padding-left {
  padding-left: 22.5px !important;
}

.u-undo-padding-left {
  padding-left: -22.5px !important;
}

.u-padding-medium {
  padding: 45px !important;
}

.u-undo-padding-medium {
  padding: -45px !important;
}

.u-padding-top-medium {
  padding-top: 45px !important;
}

.u-undo-padding-top-medium {
  padding-top: -45px !important;
}

.u-padding-right-medium {
  padding-right: 45px !important;
}

.u-undo-padding-right-medium {
  padding-right: -45px !important;
}

.u-padding-bottom-medium {
  padding-bottom: 45px !important;
}

.u-undo-padding-bottom-medium {
  padding-bottom: -45px !important;
}

.u-padding-left-medium {
  padding-left: 45px !important;
}

.u-undo-padding-left-medium {
  padding-left: -45px !important;
}

.u-padding-large {
  padding: 67.5px !important;
}

.u-undo-padding-large {
  padding: -67.5px !important;
}

.u-padding-top-large {
  padding-top: 67.5px !important;
}

.u-undo-padding-top-large {
  padding-top: -67.5px !important;
}

.u-padding-right-large {
  padding-right: 67.5px !important;
}

.u-undo-padding-right-large {
  padding-right: -67.5px !important;
}

.u-padding-bottom-large {
  padding-bottom: 67.5px !important;
}

.u-undo-padding-bottom-large {
  padding-bottom: -67.5px !important;
}

.u-padding-left-large {
  padding-left: 67.5px !important;
}

.u-undo-padding-left-large {
  padding-left: -67.5px !important;
}

.u-padding-huge {
  padding: 90px !important;
}

.u-undo-padding-huge {
  padding: -90px !important;
}

.u-padding-top-huge {
  padding-top: 90px !important;
}

.u-undo-padding-top-huge {
  padding-top: -90px !important;
}

.u-padding-right-huge {
  padding-right: 90px !important;
}

.u-undo-padding-right-huge {
  padding-right: -90px !important;
}

.u-padding-bottom-huge {
  padding-bottom: 90px !important;
}

.u-undo-padding-bottom-huge {
  padding-bottom: -90px !important;
}

.u-padding-left-huge {
  padding-left: 90px !important;
}

.u-undo-padding-left-huge {
  padding-left: -90px !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-padding-right-none {
  padding-right: 0 !important;
}

.u-padding-bottom-none {
  padding-bottom: 0 !important;
}

.u-padding-left-none {
  padding-left: 0 !important;
}

.u-display-flex {
  display: flex !important;
}

.u-justify-content-space-between {
  justify-content: space-between !important;
}

.u-justify-content-flex-end {
  justify-content: flex-end !important;
}

.u-align-items-center {
  align-items: center !important;
}

.u-flex-direction-column {
  flex-direction: column !important;
}

.u-flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-font-size-large {
  font-size: 17px !important;
}

.u-font-size-small {
  font-size: 13px !important;
}

.u-font-bold {
  font-weight: bold !important;
}

.u-font-italic {
  font-style: italic !important;
}

.u-color-white {
  color: #fff !important;
}

.u-text-align-center {
  text-align: center;
}

.u-text-truncated {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-border-top {
  border-top: 1px solid #ddd !important;
}

.u-border-bottom {
  border-bottom: 1px solid #ddd !important;
}

.u-border-right {
  border-right: 1px solid #ddd !important;
}

.u-border-left {
  border-left: 1px solid #ddd !important;
}

.u-undo-border-top {
  border-top: none !important;
}

.u-no-border {
  border: none !important;
}

.u-float-right {
  float: right !important;
}

.u-float-left {
  float: left !important;
}

.u-vertical-align-top {
  vertical-align: top !important;
}

.u-display-block {
  display: block !important;
}

.u-display-inline-block {
  display: inline-block !important;
}

.loading-width {
  width: 200px;
}

.center-align {
  margin: 0 auto;
}

.u-color-gray {
  color: #8c8c8c !important;
}

.u-color-disable {
  color: #666;
}