// Genera estilos de utilidad de márgenes por cada tamaño de $spacing
$directions: ('','-top', '-right', '-bottom', '-left');

@each $size in map-keys($spacing) {

  @each $direction in $directions {

    @if $size == 'base' {
      .u-margin#{$direction} {
        margin#{$direction}: map-get($spacing, $size) !important;
      }

      .u-undo-margin#{$direction} {
        margin#{$direction}: - map-get($spacing, $size) !important;
      }
    } @else {
      .u-margin#{$direction}-#{$size} {
        margin#{$direction}: map-get($spacing, $size) !important;
      }

      .u-undo-margin#{$direction}-#{$size} {
        margin#{$direction}: - map-get($spacing, $size) !important;
      }
    }
  }
}

@each $direction in $directions {
  .u-margin#{$direction}-none {
    margin#{$direction}: 0 !important;
  }
}
