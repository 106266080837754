$btn-default-color: $text-color;
$btn-default-bg: $component-emphatised-bg;
$btn-default-border: $btn-default-bg;

$btn-primary-color: #fff;
$btn-primary-bg: $primary-blue;
$btn-primary-border: $btn-primary-bg;

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: $brand-success;

@mixin button-variant($color, $bg, $border) {
    color: $color;
    background-color: $bg;
    border-color: $border;

    &:active,
    &.active,
    &:hover,
    &.hover,
    &:focus,
    &.focus,

    &:active:hover,
    &:active:focus,
    &:active.focus,

    &.active:hover,
    &.active:focus,
    &.active.focus {
        color: $color;
        background-color: darken($bg, 10%);
        border-color: darken($border, 10%);
    }

}

.btn {
    padding-bottom: 9px;
    font-size: inherit;
    line-height: $line-height-base;
    border: none;

    &,
    &:active,
    &.active {
        box-shadow: inset 0 -3px 0 rgba(0,0,0,.2);

        &:focus,
        &.focus {
            outline: none;
        }

    }

    .glyphicon {
        line-height: inherit;
    }

}

.btn-lg {
    padding-bottom: 13px;
    font-size: $font-size-large;
    line-height: $line-height-large;
}

.btn-sm {
    padding-bottom: 8px;
    font-size: $font-size-small;
    line-height: $line-height-small;
}

.btn-xs {
    padding-bottom: 4px;
    font-size: $font-size-small;
    line-height: $line-height-small;
}

.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.btn-no-clickable {
    cursor: auto;

    &:active {
        box-shadow: none;
    }

}
