.dropdown-menu.discharge-alerts {
    display: block;
    background-color: initial;
    position: fixed;
    top: initial;
    left: initial;
    bottom: 0;
    right: 0;
    border: initial;
    margin: initial;
    padding: 10px;

    li {
        margin-top: 10px;

        a {
            & {
                border-radius: 4px;
                background-color: #d9534f;
                padding: 10px 20px;
            }
            &:hover,
            &:focus {
                background-color: #a94442;
            }

        }
    }
}
