$table-cell-padding: 7px 10px;
$table-cell-padding-md: 7px 20px;

$table-border-color: #ddd;

.table {
    @include font-size-base;
    margin-bottom: initial;

    > thead,
    > tbody,
    > tfoot {

        > tr {
            &.active {
                cursor:not-allowed;
            }

            &.disabled {

                > td {
                    color: $disabled-text;
                    pointer-events: none;
                    background-color: $body-bg;
                }

                .btn-default {
                    color: $disabled-text;
                }
            }

            > th,
            > td {
                padding: $table-cell-padding;
                vertical-align: middle;
                @media (min-width: $screen-md-min) {
                    padding: $table-cell-padding-md;
                }
            }

            > th {
                font-size: $font-size-small;
                text-transform: uppercase;
                border-bottom: none;
            }

            > td {

                &.table__add-row {
                    padding-top: 16px;
                    vertical-align: top;

                    .glyphicon {
                        cursor: pointer;
                    }

                }

            }

        }

    }

    th {

        .form-control {
            font-weight: normal;
        }

    }

    > tfoot > tr {

        > th,
        > td {
            padding-top: spacing(small);
            padding-bottom: spacing(small);
        }

    }

}

.table-hover {

    > tbody > tr {

        &:hover {
            background-color: lighten($primary-blue, 38%);
        }
    }

}

.table--box {

    tbody {
        border: 1px solid $component-border-color;
        background-color: #fff;
    }

    td {
        max-width: 300px;
    }
}

.table--text-center {

    th,
    td {
        text-align: center;
    }

}

.table--week {

    th {

        min-width: 125px;

        > span {

            &.icon-extrahospitalary {
                color: grey;
            }

            &.icon-hospitalary {
                color: turquoise;
            }

        }

    }

    td {

        &:hover,
        &:focus {
            background-color: lighten($primary-blue, 38%);
        }

    }

}
