$primary-blue: #41C6EF;
$secundary-blue: #37A8CB;
$terciary-blue: #4A90E2;
$primary-rollover: #37A8CB;
$light-rollover: #ddd;
$dark-text: #000;
$light-text: #FFF;
$disabled-text: #999;
$advises-badge: #FF001E;

$brand-success: #5cb85c;
$brand-danger: #d9534f;

$state-success-text: #3c763d;

$state-danger-text: #a94442;

$gray-base: #000;
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 55%);

$component-emphatised-bg: #ECEEEF;

$body-bg: #f9f9f9;
$text-color: $gray-dark;

$link-color: $primary-blue;
$link-hover-color: darken($primary-blue, 15%);

$font-size-base: 14px;
$font-size-base-md: 15px;

$font-size-large: 17px;
$font-size-small: 13px;

$line-height-base: 1.5;
$line-height-large: 1.3333333;
$line-height-small: 1.5;

$font-size-h1: 24px;
$font-size-h2: 21px;
$font-size-h3: 19px;
$font-size-h4: 16px;
$font-size-h5: 16px;
$font-size-h6: 16px;

$headings-font-weight:    500;
$headings-margin-bottom: .75em;
$headings-line-height:    1.1;

$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;

$vr-unit: $font-size-base-md * $line-height-base;

$spacing: (
  tiny:   $vr-unit * 0.25,
  small:  $vr-unit * 0.5,
  base:   $vr-unit * 1,
  medium: $vr-unit * 2,
  large:  $vr-unit * 3,
  huge:   $vr-unit * 4
);

$primary-calendar: #AEFFDF;
$secundary-calendar: #AEECFF;

$imput-border: #999;

$component-border-color: #ddd;

