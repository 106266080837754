h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: initial;
    font-weight: normal;
}

h1:not([class]), .h1,
h2:not([class]), .h2,
h3:not([class]), .h3,
h4:not([class]), .h4,
h5:not([class]), .h5,
h6:not([class]), .h6 {
    font-weight: $headings-font-weight;
    margin-bottom: $headings-margin-bottom;
}

h1:not([class]), .h1 {
    font-size: $font-size-h1;
}

h2:not([class]), .h2 {
  font-size: $font-size-h2;
}

h3:not([class]), .h3 {
  font-size: $font-size-h3;
}

h4:not([class]), .h4 {
  font-size: $font-size-h4;
}

h5:not([class]), .h5 {
  font-size: $font-size-h5;
}

h6:not([class]), .h6 {
  font-size: $font-size-h6;
}

p, ul, ol, dl {

    &:not([class]) {

        + {

            h1, h2, h3, h4, h5, h6 {

                &:not([class]) {
                    padding-top: spacing(base);
                }

            }

        }

    }

}
