.fc-agendaWeek-view tr {
    height: 40px;
}

.fc-agendaDay-view tr {
    height: 40px;
}

.uib-datepicker-popup,
.uib-datepicker-popup,
.uib-datepicker-popup {

    &:focus,
    &:active,
    &:hover {
        outline: 0;
    }

    .btn {
        border-radius: 0;
    }

}

.fc-view-container {
    background-color: #fff;
}
