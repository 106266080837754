.icon-group {
    white-space: nowrap;

    .has-success & {
        color: $state-success-text;
    }

    .has-error & {
        color: $state-danger-text;
    }

    .glyphicon {
        margin-left: spacing(small);
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }

        &.disabled {
            cursor: not-allowed;
            color: #999;
        }

    }

}


