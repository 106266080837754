input[type="file"],
input[type="radio"],
input[type="checkbox"] {

    &:focus {
        outline: none;
    }

}

/* Numeric inputs */

// https://css-tricks.com/numeric-inputs-a-comparison-of-browser-defaults/

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-control {
    @include font-size-base;

    &[disabled] {
        color: rgba($text-color,.5);
    }

}

.form-control--w100 {
    width: 100px;
}

.form-control--w150 {
    width: 150px;
}

label,
.control-label {
    display: inline-block;
    margin-bottom: spacing(tiny);
    font-weight: bold;
}

.unit-label {
    display: inline-block;
    margin-bottom: spacing(tiny);
}

.form-group {
    margin-bottom: spacing(base);
}

.form-inline {

    .form-group {
        margin-right: spacing(small);

        &:last-child {
            margin-left: 0;
        }

    }

}

.form-horizontal {

    .control-label {
        @media (min-width: $screen-sm-min) {
            padding-top: spacing(tiny);
        }
    }

}

.uib-time input {
    width: 60px;
}

.input-group-addon {
    padding-left: spacing(tiny);
    padding-right: spacing(tiny);
    font-size: $font-size-small;
}

.min-width-70 {
    min-width: 70px;
}
