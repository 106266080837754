.btn-list {

    .btn {
        margin-bottom: spacing(tiny);
        margin-right: spacing(tiny);

        &:first-child {
            margin-left: 0;
        }

    }

}
